import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import clsx from "clsx";
import {getAccountApplications, processApp} from "../mat-data/core/_requests";
import React, {FC, useEffect, useState} from "react";
import {IApplicationDtoApiResponse} from "../mat-data/core/_models";
import download from 'js-file-download';
import {ISelectedFile} from "./core/_model";
import FileFormModal from "../mat-data/file/file-form/FileFormModal";
import MultipFileFormModal from "./MultiFileFormModal";

const API_URL = process.env.REACT_APP_SECURITY_SERVICE_API_URL;
export const FILE_URL = `${API_URL}/file/load/`;

type props = {
    formModalShow:any[];
    setApp: any;
    selectedFiles: any;
    setSelectedFiles: any;
}
const DashboardPage:FC<props> = (props) => {
    const intl = useIntl()
    const [modalShow, setModalShow] = props.formModalShow;
    const setApp = props.setApp;
    //const setApp = props.setApp;
    const [apps, setApps] = useState<IApplicationDtoApiResponse[]>([]);
    //const [selectedFiles, setSelectedFiles] = useState<ISelectedFile[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        getAccountApplications().then(r => setApps(r.data));
    }, []);

    const setFile = (e, inputKey:string, id: number) => {
        const selectedFile = e.target.files[0];

        if (selectedFile == null) {
            props.setSelectedFiles([...props.selectedFiles.filter(f => f.id != id)]);
            return;
        }
        let appSelections: ISelectedFile = props.selectedFiles.find(i => i.id == id) as ISelectedFile;

        if (appSelections == undefined) {
            appSelections = {id: id, files: []}
        }

        appSelections.files = [...appSelections.files.filter(i => i.key!=inputKey), {key:inputKey, file:selectedFile}!];

        props.setSelectedFiles([...props.selectedFiles.filter(f => f.id != id), appSelections])
    }

    useEffect(() => {
        console.log(props.selectedFiles);
    }, [props.selectedFiles]);

    const openChooseFile = (app) => {
        if (app.multipleFileUpload) {
            setModalShow(true);
            setApp(app);
        } else {
            const input = document.querySelector('#fileUpload_' + app.id) //getElementById('fileUpload_'+id).click()
            if (input != null) {
                // @ts-ignore
                input.click()
            }
        }
    }

    const process = (id) => {
        const app = props.selectedFiles.find(f => f.id == id);
        if (app == null)
            return;
        setSubmitting(true);
        processApp(app.files, id).then(r => {
            console.log(r.headers);
            console.log(r.headers['x-file-name']);
            download(r.data, r.headers['x-file-name']);
        }).finally(() => setSubmitting(false))
    }

    return (
        <>
            <div className='row g-5 g-xl-8 justify-content-center'>

                {apps.map(a =>
                    <div key={a.id} className="col-xxl-6">

                        <div className="card card-flush h-md-100">
                            <div className="card-body py-9">
                                <div className="row gx-9 h-100">
                                    <div className="col-sm-6 mb-10 mb-sm-0" style={{height: '336px'}}>
                                        <div
                                            className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-400px min-h-sm-100 h-100"
                                            style={{
                                                backgroundSize: '100% 100%',
                                                backgroundImage: 'url(' + (a.logoId != undefined ? FILE_URL + a.logoId : '/media/stock/600x600/img-12.jpg') + ')'
                                            }}>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="d-flex flex-column h-100">
                                            <div className="mb-2">
                                                <div className="d-flex flex-stack mb-6">
                                                    <div className="flex-shrink-0 me-5">
                                                            <span
                                                                className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1">{a.acceptedExtensions}</span>
                                                        <span className="text-gray-800 fs-1 fw-bold">{a.name}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                    <span
                                                        className="fw-semibold text-gray-600 fs-6 mb-8 d-block">{a.description}</span>
                                            </div>

                                            <div className="d-flex flex-column mt-auto bd-highlight">
                                                {a.templateId != undefined ?
                                                    <a href={FILE_URL + a.templateId}
                                                       className="btn btn-light-warning" target="_blank">
                                                        <i className="bi bi-download fs-4 me-2"></i>
                                                        {intl.formatMessage({id: 'DASHBOARD.DOWNLOAD_TEMPLATE'})}
                                                    </a>
                                                    : null}
                                                <button className="btn btn-light-success mt-2"
                                                        onClick={() => openChooseFile(a)}>
                                                    <i className="bi bi-upload fs-4 me-2"></i>
                                                    {intl.formatMessage({id: 'DASHBOARD.BUTTON_CHOOSE_FILE'})}
                                                </button>

                                                <input className="form-control"
                                                       type="file"
                                                       id={"fileUpload_" + a.id}
                                                       onChange={(e) => setFile(e, "file", a.id)}
                                                       style={{display: 'none'}}
                                                       accept={a.acceptedExtensions}/>
                                                <button
                                                    disabled={!props.selectedFiles.find(f => f.id == a.id)}
                                                    onClick={() => process(a.id)}
                                                    className="btn btn-light-primary mt-2"
                                                    data-kt-indicator={isSubmitting ? 'on' : 'off'}
                                                >
                                                        <span
                                                            className='indicator-label'>
                                                        <i className="bi bi-download fs-4 me-2"></i>
                                                            {intl.formatMessage({id: 'DASHBOARD.BUTTON_PROCESS'})}
                                                        </span>
                                                    <span className="indicator-progress">
                                            {intl.formatMessage({id: 'GENERAL.PLEASE_WAIT'})} <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </>
    );
}

const DashboardWrapper = () => {
    const intl = useIntl()
    const formModalShow = useState<boolean>(false);
    const [app,setApp] = useState<IApplicationDtoApiResponse>();
    const [selectedFiles, setSelectedFiles] = useState<ISelectedFile[]>([]);
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage formModalShow={formModalShow} setApp={setApp} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
            <MultipFileFormModal show={formModalShow} app={app} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        </>
    )
}

export {
    DashboardWrapper
}
