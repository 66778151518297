import {FC} from 'react'

const RightToolbar: FC = () => {
  return (
    <>

    </>
  )
}

export {RightToolbar}
