import { Outlet, Route, Routes} from "react-router-dom";

import React from "react";
import ApplicationPageWrapper from "./application/ApplicationPage";
import FilePageWrapper from "./file/FilePage";
import ApplicationFormWrapper from "./application/application-form/ApplicationFormWrapper";

const MatdataPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path='applications' element={ <ApplicationPageWrapper/> } />
                <Route path='applications/form/:appId' element={ <ApplicationFormWrapper/> } />
                <Route path='files' element={ <FilePageWrapper/> } />
            </Route>
        </Routes>
    )
}

export default MatdataPage;