import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect} from "react";
import {Languages} from "../../../_metronic/partials/layout/header-menus/Languages";
import {KTIcon} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {useLang, setLanguage} from '../../../_metronic/i18n/Metronici18n';

const HomePage = () => {
    const intl = useIntl()
    useEffect(() => {
        const lang = localStorage.getItem('i18nConfig');
        if (lang == null) {
            setLanguage('en');
        }
    }, []);

    return (
        <>
            <div className='row g-5 g-xl-8 justify-content-center'>
                <div className="col-xxl-8">
                    <div className={`card mb-5 mb-xl-8`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                        <span
                            className='card-label fw-bold fs-3 mb-1'></span>
                            </h3>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a file'
                            >
                                <Link to={'/auth'} className='btn btn-sm btn-light-primary'>
                                    <KTIcon iconName='user' className='fs-2'/>
                                    {intl.formatMessage({id: 'HOME.LOGIN'})}
                                </Link>
                            </div>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div className='card-body py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <p className='text-center'><img src='/media/matdata/matdata_home_1.svg' style={{width:'100%'}} /></p>
                                {intl.locale == 'tr' ?
                                    <>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            Mat-DATA, sac metal şekillendirme kalıp analizlerinde gereken malzeme
                                            kartlarını kolayca hazırlamak için geliştirildi.
                                            İster laboratuvardan gelen ham çekme testi verilerini yükleyip malzeme
                                            kartını alabilirsiniz, isterseniz program A’dan gelen malzeme kartını
                                            program B’ye çevirebilirsiniz.
                                        </p>
                                        <p className='text-center'><img src='/media/matdata/matdata_introduce_tr.svg'/></p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            mat-DATA ile iş akışınızı basitleştirin, malzeme kartı oluşturma ve çeviri
                                            sürecini optimize ederek verimliliği artırın. mat-DATA, aşağıdaki dosya
                                            formatları ile uyumludur:
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            Ham çekme testi verileri için:
                                        </p>
                                        <p className='ps-5 fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            CSV<br/>
                                            TRA<br/>
                                            XLS<br/>
                                            XLSX
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            Malzeme kartları için:
                                        </p>
                                        <p className='ps-5 fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            .key<br/>
                                            .mat<br/>
                                            .material<br/>
                                            .psm<br/>
                                            .rad<br/>
                                            .stm

                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            Aradığınız dosya formatı bu listede değilse, bize yazın. mat-DATA.com
                                            tamamen size özel çözümler geliştirmek amaçlı hazırlandı.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4 text-center'>
                                            Do you run sheet metal forming simulation? mat-DATA is for you!
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4 text-center'>
                                            Upload tensile test data to mat-DATA.com and generate your material card in seconds.
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4 text-center'>
                                            Translate cards from one software to another: Upload your material card designed for software package A and promptly download the card tailored for software package B.
                                        </p>

                                        <p className='text-center'><img src='/media/matdata/matdata_introduce_en.svg' style={{width:'100%'}}/></p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            mat-DATA is compatible with the following file formats:
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            For raw tensile test data:
                                            <p className='ps-5'>*.csv <br/>*.tra <br/>*.xls <br/>*.xlsx</p>
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            For material cards:
                                            <p className='ps-5'>
                                                .key<br/>
                                                .mat<br/>
                                                .material<br/>
                                                .psm<br/>
                                                .rad<br/>
                                                .stm
                                            </p>
                                        </p>
                                        <p className='fw-semibold fs-5 text-gray-600 text-gray-900 mt-4'>
                                            Reach out to us at <a href="mailto:info@mat-data.com">info@mat-data.com</a> and we'll work together to address your specific requirements!
                                        </p>
                                        <p className='fw-bold fs-5 text-gray-600 text-gray-900 mt-4 text-center'>
                                            Simplify your workflow with mat-DATA and streamline the process
                                            of material card generation and translation for enhanced efficiency
                                        </p>

                                    </>
                                }
                                {/* end::Table */}
                            </div>
                            {/* end::Table container */}
                        </div>
                        {/* begin::Body */}
                    </div>
                </div>
            </div>
        </>
    );
}

const HomeWrapper = () => {
    const intl = useIntl()
    // {intl.formatMessage({id: 'MENU.DASHBOARD'})}
    return (
        <>
            <PageTitle breadcrumbs={[]}></PageTitle>
            <HomePage/>
        </>
    )
}

export {
    HomeWrapper
}
