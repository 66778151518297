import React, {FC, useEffect, useRef, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import LPModal from "../../modules/modal/LPModal";
import {IApplicationDtoApiResponse} from "../mat-data/core/_models";
import {ISelectedFile} from "./core/_model";

type Props = {
    show: any[];
    app: any;
    setSelectedFiles: any;
    selectedFiles: any;
}

const MultipFileFormModal: FC<Props> = (props) => {
    const intl = useIntl();
    const [modalShow, setModalShow] = props.show;
    const [selectedFiles, setSelectedFiles] = useState({});
    const app = props.app;

    const messageRequired = intl.formatMessage({id: 'MESSAGE.REQUIRED'})
    const formSchema = Yup.object({
    });


    const [initValues, setInitialValues] = useState<any>({ })
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!modalShow) {
            clearForm();
        }
    }, [modalShow])

    useEffect(() => {
        if (app != undefined) {
            //TODO
            console.log(app);
        }
    }, [app])
    const submitForm = (values: any, actions: FormikValues) => {
        setSubmitting(true);

        const fileList = Object.keys(selectedFiles).map(i => {
            return { key: i, file: selectedFiles[i] };
        })

        props.setSelectedFiles([...props.selectedFiles.filter(f => f.id != app.id), {id:app.id, files: fileList}]);
        setSubmitting(false);
        setModalShow(false);
    }
    const cancel = () => setModalShow(false);
    const clearForm = () => {
        setInitialValues({
            name: "",
            file: undefined
        })
    }

    return (
        <>
            <LPModal
                show={props.show}
                title={intl.formatMessage({id: 'DASHBOARD.MULTI_FILE_UPLOAD.TITLE'})}
                modalId={"application-form-modal"}
                dialogClassName={'mw-650px'}
            >
                <Formik enableReinitialize={true} validationSchema={formSchema}
                        initialValues={initValues}
                        onSubmit={submitForm}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          setFieldValue,
                          isValid,
                          dirty,
                      }) => (
                        <Form className='mx-auto mw-600px w-100 pt-5 pb-10'
                              id='kt_create_application_form'>
                            <Field type="hidden" name='id'
                                   className='form-control form-control-lg form-control-solid'/>
                            {
                                app.inputs.sort((a, b) =>
                                    a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map(i =>
                                    <div key={i.id} className='fv-row mb-10'>
                                        <label
                                            className='form-label required'>{i.title}</label>
                                        <Field name={i.varName} type='file'
                                               placeholder={i.title}
                                               accept={i.acceptedExtensions}
                                               onChange={(event) => {
                                                   console.log(event);
                                                   const selectedFiles_ = selectedFiles;
                                                   selectedFiles_[i.varName] = event.target.files[0];
                                                   setSelectedFiles(selectedFiles_);
                                               }}
                                               className='form-control form-control-lg form-control-solid'/>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name={i.varName}/>
                                        </div>
                                    </div>
                                )
                            }

                            <div className='text-center pt-15'>
                                <button
                                    type='button'
                                    onClick={() => cancel()}
                                    className='btn btn-light me-3'
                                    disabled={isSubmitting}
                                >
                                    {intl.formatMessage({id: 'DASHBOARD.MULTI_FILE_UPLOAD.DISCARD'})}
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-indicator={isSubmitting ? 'on' : 'off'}
                                    disabled={isSubmitting}
                                >
                                    <span
                                        className='indicator-label'>{intl.formatMessage({id: 'DASHBOARD.MULTI_FILE_UPLOAD.SUBMIT'})}</span>
                                    <span className="indicator-progress">
                                            {intl.formatMessage({id: 'GENERAL.PLEASE_WAIT'})} <span
                                        className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </LPModal>
        </>
    );
}

export default MultipFileFormModal;