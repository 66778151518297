import React, {FC, useEffect, useState} from "react";
import {ErrorMessage, Field, FieldArray, Form, Formik, FormikValues} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";
import {IApplicationInputs, IFileDtoApiResponse, IUpsertApplication} from "../../core/_models";
import {createApplication, getApplicationById, getFiles, updateApplication} from "../../core/_requests";
import {PageLink, PageTitle} from "../../../../../_metronic/layout/core";
import {useNavigate, useParams} from "react-router-dom";
import {KTIcon} from "../../../../../_metronic/helpers";

type Props = {}

const ApplicationForm: FC<Props> = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const params = useParams();
    const [isMultipleUpload, setMultipleUpload] = useState(false);

    const messageRequired = intl.formatMessage({id: 'MESSAGE.REQUIRED'})
    const formSchema = Yup.object({
        name: Yup.string().required(messageRequired),
        //description: Yup.string(),
        acceptedExtensions: Yup.string().required(messageRequired),
        url: Yup.string().required(messageRequired),
        //templateId: Yup.number(),
        active: Yup.boolean(),
        multipleFileUpload: Yup.boolean(),
        inputs: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number(),
                    title: Yup.string().required(messageRequired),
                    varName: Yup.string().required(messageRequired),
                    acceptedExtensions: Yup.string().required(messageRequired),
                })
            )
    });

    const [initValues, setInitialValues] = useState<IUpsertApplication>({
        acceptedExtensions: "",
        active: true,
        multipleFileUpload: true,
        name: "",
        url: "",
        inputs: []
    })
    const [isSubmitting, setSubmitting] = useState(false);
    const [files, setFiles] = useState<IFileDtoApiResponse[]>([]);

    useEffect(() => {
        clearForm();
        getFiles().then(r => setFiles(r.data));
        if (params.appId != "-1") {
            getApplicationById(params.appId).then(r => {
                setInitialValues(r.data);
                setMultipleUpload(r.data.multipleFileUpload);
            }).catch(reason => {
                if (reason.response.data.error.message) {
                    toast.error(reason.response.data.error.message);
                } else {
                    toast.error(intl.formatMessage({id: 'MESSAGE.API_ERROR'}));
                }
            })
        }
    }, [params.appId])

    const submitForm = (values: IUpsertApplication, actions: FormikValues) => {
        setSubmitting(true)

        const apiCall = values.id == undefined ? createApplication(values) : updateApplication(values);
        apiCall.then(r => {
            toast.success(intl.formatMessage({id: 'MESSAGE.API_SUCCESS'}));
            navigate('/applications');
        }).catch(reason => {
            if (reason.response.data.error.message) {
                toast.error(reason.response.data.error.message);
            } else {
                toast.error(intl.formatMessage({id: 'MESSAGE.API_ERROR'}));
            }
        }).finally(() => {
            setSubmitting(false);
        })
    }
    const cancel = () => navigate('/applications');
    const clearForm = () => {
        setInitialValues({
            acceptedExtensions: "",
            active: true,
            multipleFileUpload: false,
            name: "",
            url: "",
            inputs: []
        })
    }

    const formOnChange = (e) => {
        console.log(e.target.name);
        console.log(e.target);
        console.log(e.target.value);

        if (e.target.name === 'multipleFileUpload') {
            setMultipleUpload(e.target.value=='false');
        }
    }

    return (
        <>
            <div className={`card mb-5 mb-xl-8`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span
                            className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'APPLICATION_FORM.TITLE'})}</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'></span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                    >
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={initValues}
                            onSubmit={submitForm}>
                        {({values, errors, touched}) => (
                            <Form onChange={formOnChange} className='mx-auto mw-600px w-100 pt-5 pb-10'
                                  id='kt_create_application_form'>
                                <Field type="hidden" name='id'
                                       className='form-control form-control-lg form-control-solid'/>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label required'>{intl.formatMessage({id: 'APPLICATION_FORM.NAME'})}</label>
                                    <Field name='name'
                                           placeholder={intl.formatMessage({id: 'APPLICATION_FORM.NAME'})}
                                           className='form-control form-control-lg form-control-solid'/>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='name'/>
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label'>{intl.formatMessage({id: 'APPLICATION_FORM.DESCRIPTION'})}</label>
                                    <Field name='description'
                                           placeholder={intl.formatMessage({id: 'APPLICATION_FORM.DESCRIPTION'})}
                                           className='form-control form-control-lg form-control-solid'/>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label required'>{intl.formatMessage({id: 'APPLICATION_FORM.ACCEPTED_EXTENSIONS'})}</label>
                                    <Field name='acceptedExtensions'
                                           placeholder={intl.formatMessage({id: 'APPLICATION_FORM.ACCEPTED_EXTENSIONS'})}
                                           className='form-control form-control-lg form-control-solid'/>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='acceptedExtensions'/>
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label required'>{intl.formatMessage({id: 'APPLICATION_FORM.URL'})}</label>
                                    <Field name='url'
                                           placeholder={intl.formatMessage({id: 'APPLICATION_FORM.URL'})}
                                           className='form-control form-control-lg form-control-solid'/>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='url'/>
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label'>{intl.formatMessage({id: 'APPLICATION_FORM.TEMPLATE'})}</label>
                                    <Field
                                        component='select'
                                        name='templateId'
                                        className='form-select form-select-lg form-select-solid'
                                    >
                                        <option></option>
                                        {
                                            files
                                                .map(t => <option key={t.id}
                                                                  value={t.id}>{t.name}</option>)
                                        }
                                    </Field>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='templateId'/>
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label
                                        className='form-label'>{intl.formatMessage({id: 'APPLICATION_FORM.LOGO'})}</label>
                                    <Field
                                        component='select'
                                        name='logoId'
                                        className='form-select form-select-lg form-select-solid'
                                    >
                                        <option></option>
                                        {
                                            files
                                                .map(t => <option key={t.id}
                                                                  value={t.id}>{t.name}</option>)
                                        }
                                    </Field>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='logoId'/>
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <Field name='active' id="active" type='checkbox' className='form-check-input'/>
                                        <label className="form-check-label">
                                            {intl.formatMessage({id: 'APPLICATION_FORM.ACTIVE'})}
                                        </label>
                                    </div>

                                </div>

                                <div className='fv-row mb-10'>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <Field name='multipleFileUpload' id="multipleFileUpload" type='checkbox'
                                               className='form-check-input'/>
                                        <label className="form-check-label">
                                            {intl.formatMessage({id: 'APPLICATION_FORM.MULTIPLE_FILE_UPLOAD'})}
                                        </label>
                                    </div>

                                </div>
                                {isMultipleUpload ?
                                <FieldArray
                                    name="inputs"
                                    render={arrayHelpers => (
                                        <div>
                                            <div className='row mb-5'>
                                                <div className='col-lg-4 px-5'> {intl.formatMessage({id: 'APPLICATION_FORM.MFU.TITLE'})} </div>
                                                <div className='col-lg-3 px-5'> {intl.formatMessage({id: 'APPLICATION_FORM.MFU.VAR_NAME'})} </div>
                                                <div className='col-lg-5 px-5'> {intl.formatMessage({id: 'APPLICATION_FORM.ACCEPTED_EXTENSIONS'})} </div>
                                            </div>
                                            {values.inputs && values.inputs.length > 0 ? (
                                                values.inputs.map((friend, index) => (
                                                    <div className='row mb-5' key={index}>
                                                        <Field type="hidden" name={`inputs[${index}].id`} />
                                                        <div className='col-lg-4'>
                                                            <Field name={`inputs[${index}].title`}
                                                                   placeholder={intl.formatMessage({id: 'APPLICATION_FORM.MFU.TITLE'})}
                                                                   className='form-control form-control-sm form-control-solid'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name={`inputs[${index}].title`}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <Field name={`inputs[${index}].varName`}
                                                                   placeholder={intl.formatMessage({id: 'APPLICATION_FORM.MFU.VAR_NAME'})}
                                                                   className='form-control form-control-sm form-control-solid'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name={`inputs[${index}].varName`}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-5'>
                                                            <Field name={`inputs[${index}].acceptedExtensions`}
                                                                   placeholder={intl.formatMessage({id: 'APPLICATION_FORM.ACCEPTED_EXTENSIONS'})}
                                                                   className='form-control form-control-sm form-control-solid'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name={`inputs[${index}].acceptedExtensions`}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12 mt-1'>
                                                            <button
                                                                type="button"
                                                                className={'btn btn-light-primary btn-sm me-auto w-100'}
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >
                                                                <KTIcon iconName='trash' className='fs-3'/>
                                                            </button>
                                                        </div>

                                                    </div>
                                                ))
                                            ) : null}
                                            <button type="button" className={'btn btn-light-primary me-auto'}
                                                    onClick={() => arrayHelpers.push({id:'', title:'',varName:'',acceptedExtensions:''})}>
                                                {/* show this when user has removed all friends from the list */}
                                                {intl.formatMessage({id: 'APPLICATION_FORM.MFU.ADD_INPUT'})}
                                            </button>

                                        </div>
                                    )}
                                />
                                    : null }

                                <div className='text-center pt-15'>
                                    <button
                                        type='button'
                                        onClick={() => cancel()}
                                        className='btn btn-light me-3'
                                        disabled={isSubmitting}
                                    >
                                        {intl.formatMessage({id: 'APPLICATION_FORM.DISCARD'})}
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-indicator={isSubmitting ? 'on' : 'off'}
                                        disabled={isSubmitting}
                                    >
                                    <span
                                        className='indicator-label'>{intl.formatMessage({id: 'APPLICATION_FORM.SUBMIT'})}</span>
                                        <span className="indicator-progress">
                                            {intl.formatMessage({id: 'GENERAL.PLEASE_WAIT'})} <span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

const ApplicationFormWrapper = () => {
    const intl = useIntl();

    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'MENU.APPLICATIONS'}),
            path: '/applications',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'MENU.APPLICATIONS'})}</PageTitle>
            <ApplicationForm/>

        </>
    )
}

export default ApplicationFormWrapper;